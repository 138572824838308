
import Vue from "vue";
import { mapGetters } from "vuex";
import MentorcastRatings from "@/components/Mentorcast/MentorcastRatings.vue";

export default Vue.extend({
  name: "MentorcastRatingPage",
  components: {
    MentorcastRatings
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      mentorcast: "mentorcast/getMentorcast"
    })
  },
  mounted(): void {
    this.$store
      .dispatch("mentorcast/GET_MENTORCAST", this.$route.params.id)
      .then(data => {
        if (!data.can_i_rate_it || data.is_rated || data.isUserRatingBanned) {
          this.$router.push(`/mentorcasts/${data.uuid}`);
        }
      });
  }
});
